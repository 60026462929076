<template>
 <div style="position: relative;">
	 <div class="fanhui" @click="handleBack">
	 	<img src="../../assets/fanhui.png" >
		<span>
			返回
		</span>
	 </div>
 	<div style="width: 100%;height:calc(100vh - 90px);">
 	  <div id="viewer"></div>
 	  <div id="show3d" style="position:relative;"></div>
 	</div>
 </div>
</template>
<script>
import {Viewer} from 'photo-sphere-viewer'
import {MarkersPlugin} from 'photo-sphere-viewer/dist/plugins/markers'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import 'photo-sphere-viewer/dist/plugins/markers.css';
//模型加载

import * as Three from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import {OBJLoader} from 'three-obj-mtl-loader'
export default {
  data(){
    return{
      viewer:'',
      imgurl:'',
    //   imgurl1:require('../../assets/vr.jpg'),
    //   imgurl2:require('../../assets/vr.jpg'),
    //   imgurl3:require('../../assets/vr.jpg'),
      //模型
      // 相机
      camera: null,
      // 场景
      scene: null,
      // 渲染器对象
      renderer: null,
      // 材质对象
      mesh: null,
      controls:null,
      //声音播报
      num: 0,
      new_num: 0,
    }
  },
  created () {
      this.imgurl='/gsmm/api/v1/'+this.$route.query.url
    //   console.log(this.$route.query.url)
//   setTimeout(() => {
//     L2Dwidget.init({
//      "display": {
//           "superSample": 2,
//           "width": 200,
//           "height": 400,
//           "position": "left",//设置看板娘的位置
//           "hOffset": 0,
//           "vOffset": 0
//             }
//     })
//   }, 1000)
},
  mounted(){
    this.viewer = new Viewer({
      container:document.querySelector('#viewer'),
      panorama:this.imgurl,
      size:{
        width: '100%',
        height: '100%',
      },
      plugins: [
        // [MarkersPlugin, {
        //   markers: [
        //     {
        //       id:'circle',
        //       tooltip:'华山古树',
        //       longitude: 1.5,
        //       latitude: -0.69,
        //       anchor: 'center right',
        //       image: 'https://photo-sphere-viewer.js.org/assets/pin-blue.png',
        //       width: 32,
        //       height: 32,
        //     },
        //      {
        //       id:'circle1',
        //       tooltip:'古树',
        //       longitude: 1.5,
        //       latitude: 0,
        //       anchor: 'center right',
        //       image: 'https://photo-sphere-viewer.js.org/assets/pin-blue.png',
        //       width: 32,
        //       height: 32,
        //     },
        //   ],
        // }],
      ],
    });

    const markersPlugin = this.viewer.getPlugin(MarkersPlugin);

    markersPlugin.on('select-marker', (e, marker) => {
      this.viewer.animate({
        longitude: marker.config.longitude,
        latitude: marker.config.latitude,
        zoom: 100,
        speed: '-2rpm',
      }).then(() =>
          this.viewer.setPanorama(
              this.imgurl2
          ).then(() =>
                  markersPlugin.updateMarker({
                    id: marker.id,
                    longitude: 2.0,
                    latitude: -0.69,
                  }),
              this.viewer.animate({
                zoom: 50,
                speed: '2rpm',
              }).then(() =>
                  this.imgurl2 = this.imgurl3,
                  console.log("继续操作")
              )
          )
      )
    });

    // this.init();
    // this.loadObj();
    //this.speckText();
  },
  methods:{
	  handleBack(){
		  this.$router.go(-1)
	  },
    //语音播报
    speckText() {
      // var url= "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=" + encodeURI(str);
      var url= "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=森森古树越千年，势盖群芳脱俗缘。岳阳县月田镇花苗村黄帽山生长有一棵千年马尾松，估测树龄1000年，传说树龄2200余年，胸径0.95米，树高8.2米，冠幅28米，远近有名。\n" +
          "\n" +
          "    古松远观如伞，青葱茂盛。近看如柱盘龙，顶端两枝朝两向伸出，枝杈如群虬缠附，向下成伞状垂于地面，枝长者竟达27米。\n" +
          "\n" +
          "    关于古松，流传着不少传说。相传汉高祖刘邦得天下后，其军师张良因看破红尘，隐匿民间。刘邦为追回张良，寻访至此。其山因刘邦遗皇帽于此而得名，这颗古松也因此被称为“皇帽古松”。\n" +
          "\n" +
          "　　树下有一口清池，居于离树三十米处，名曰“白鹤池”。传说其间有一神鹤，行踪怪异，白天飞至洞庭觅食，到了夜晚就飞到古松上宿眠。四时更替，如是往返。树虽高寿，却不长高，民间说都是白鹤所为。故古松又被称为“白鹤松”。在古松附近，侵华日军曾屠杀我红军战士数名，树旁建有烈士纪念塔。\n" +
          "\n" +
          "千年古松，高祖遗物，白鹤仙居。如今，这颗古松虽历经千年，仍枝繁叶茂，伟岸遒劲，宛如一位历经风雨的老人，阅尽人事沧桑，福荫一地千年。";
      // var adieo = video;
      new Audio(url).play();
      //var n = new Audio(url);
      //n.src = url;
      //n.play();
    },

    // 初始化
    init () {
      // 相机位置
      let width=window.innerWidth / 2;
      let height=window.innerHeight / 2;
      // 创建场景
      this.scene = new Three.Scene();
      let k = width/height;
      let s = 150;
      this.camera = new Three.OrthographicCamera(-s*k,s*k,s,-s,1,1000);
      this.camera.position.set(80, 100, 60);
      this.camera.lookAt(new Three.Vector3(1, 80, 0));
      this.scene.add(this.camera);
      // 创建点光源
      let light=new Three.DirectionalLight(0xffaaff);
      light.position.set(80,100,50);
      this.scene.add(light);
      // 创建渲染器
      this.renderer = new Three.WebGLRenderer({antialias: true})
      this.renderer.setSize(width, height)
      document.body.appendChild(this.renderer.domElement);
      // 创建控件对象
      this.controls=new OrbitControls(this.camera,this.renderer.domElement);
      this.controls.addEventListener('change',this.animate);
    },
    // 创建obj模型
    loadObj () {
      let that=this;
      let objloader=new OBJLoader();
      objloader.load('static/obj/male02.obj',function(obj){
        that.mesh=obj;
        that.scene.add(that.mesh);
        that.animate();
      })
    },
    // 创建动画
    animate () {
      this.renderer.render(this.scene, this.camera)
    },
  }
}
</script>
<style lang="less">
.fanhui{
	position: absolute;
	z-index: 4;
	right:30px;
	top: 10px;
	font-size: 16px;
	color: #fff;
	display: flex;
	align-items: center;
	span{
		margin-left: 10px;
	}
}	
.fanhui:hover{
	cursor: pointer;
}
</style>
